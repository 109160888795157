import request from '@/utils/request';


// 主试激活
export function Activation(data) {
  return request({
    url: '/wisc/youth/activation/index',
    method: 'POST',
    data
  });
}

// 检测账号
export function VerifyAccount(params) {
  return request({
    url: '/wisc/youth/activation/verifyAccount',
    method: 'GET',
    params
  });
}