<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <div class="activation-box" @click="goActivation">
        <img src="/images/activation-icon.png" alt="">
        <span>新主试激活</span>
      </div>
    </div>
    <div class="main-body">
      <div class="form-box">
        <div class="header">
          <div class="img"><img src="/images/small-teacher-img.png" alt=""></div>
          <div class="name">用户激活</div>
          <div class="describe">(带 <span>*</span> 的为必填项)</div>
        </div>
        <div class="form-box-sh">

          <a-form-model ref="form" :model="formModel"  :rules="formRules" style="padding:40px 0 " autoComplete="off" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item prop="account" label="账号" style="margin: 0px 0 20px 0;border-bottom: 1px solid #F2F3F5;padding-bottom: 20px">
              <div class="flex">
                <a-input allowClear placeholder="账号" class="is-input" v-model="formModel.account">
                  <span slot="suffix">
                    <span class="is-btn" @click="verifyAccount">检测用户名是否可用</span>
                  </span>
                </a-input>
                <span class="form-de">可以使用中文、英文、数字，一旦注册用户名不能更改。</span>
              </div>
            </a-form-model-item>

            <a-form-model-item label="证书编号" prop="certificate_no">
              <div class="flex">
                <a-input allowClear v-model="formModel.certificate_no" prop="certificate_no" placeholder="证书编号" class="cu-input"/>
                <span class="form-de">主试资格证编号（例如：021000000）</span>
              </div>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name">
              <div class="flex">
                <a-input allowClear placeholder="姓名" class="cu-input" v-model="formModel.name"/>
                <span class="form-de">主试真实姓名!（例如：张三）</span>
              </div>
            </a-form-model-item>
            <a-form-model-item label="身份证号码" prop="id_card_no">
              <div class="flex">
                <a-input placeholder="身份证号码" class="cu-input" v-model="formModel.id_card_no"/>
                <span class="form-de">正确的身份证号码 </span>
              </div>
            </a-form-model-item>
            <a-form-model-item label="Email" prop="email">
              <div class="flex">
                <a-input placeholder="Email" class="cu-input" v-model="formModel.email"/>
                <span class="form-de">准确的Email地址，以便接收认证信息或重设密码 </span>
              </div>
            </a-form-model-item>
            <a-form-model-item label="密码" prop="pwd">
              <div class="flex">
                <a-input type="password" v-model="formModel.pwd" placeholder="密码" class="cu-input"/>
                <span class="form-de">密码由6-20个字符组成，为保证安全请使用英文字母加数字或符号的 组合密码</span>
              </div>
            </a-form-model-item>
            <a-form-model-item label="重输密码" prop="pwd1">
              <div class="flex">
                <a-input type="password" placeholder="重输密码" v-model="formModel.pwd1" class="cu-input"/>
                <span class="form-de">请再输入一遍您上面输入的密码 </span>
              </div>
            </a-form-model-item>
            <a-form-model-item label="提示问题" prop="prompt_questions">
              <div class="flex">
                <a-input placeholder="提示问题" class="cu-input" v-model="formModel.prompt_questions"/>
                <span class="form-de">为了方便您以后重设密码，请预设一个提示问题(例如:你是谁?)。</span>
              </div>
            </a-form-model-item>
            <a-form-model-item label="问题答案" prop="prompt_answer">
              <div class="flex">
                <a-input placeholder="问题答案" class="cu-input" v-model="formModel.prompt_answer"/>
                <span class="form-de">输入对上面问题的回答(例如:我是小李) </span>
              </div>
            </a-form-model-item>
            <div class="line" style="border-bottom:1px solid  #eeeeee;margin: 10px 0"></div>
            <a-form-model-item label="" :wrapper-col="{span:18,offset:4}" style="margin-bottom: 0">
             <div style="display: flex;margin-top: 15px">
               <div class="btn" style="flex: 1;margin-right: 10px" @click="activation">激活</div>
               <div class="btn sh" @click="goLogin" style="flex: 1;margin-left: 10px">返回登录页面</div>
             </div>
            </a-form-model-item>
          </a-form-model>

        </div>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import * as Api from './api';
import * as commonApi from '@/commonApi';
import {VerifyAccount} from "./api";
import {message} from "ant-design-vue";

export default {
  data() {
    var validateMail = (rule, value, callback) => {
      if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        callback(new Error('请输入正确的邮箱'));
      }
      callback();
    };
    var validatePass = (rule, value, callback) => {
      if (this.formModel.pwd != this.formModel.pwd1) {
        callback(new Error('密码输入不一致'));
      }
      callback();
    };

    return {
      labelCol: {span: 3},
      wrapperCol: {span: 20},
      formModel: {
        account: '',
        certificate_no: '',
        name: '',
        id_card_no: '',
        email: '',
        pwd: '',
        pwd1: '',
        prompt_questions: '',
        prompt_answer: ''
      },
      formRules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'change' },
        ],
        certificate_no: [
          { required: true, message: '请输入证书编号', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'change' },
        ],
        id_card_no: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
        ],
        email: [
          { validator:validateMail, trigger: 'change' },
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'change' },
        ],
        pwd1: [
          { required: true, message: '请重新输入密码', trigger: 'change' },
          { validator: validatePass, trigger: 'change' },
        ],
        prompt_questions: [
          { required: true, message: '请输入提示问题', trigger: 'change' },
        ],
        prompt_answer: [
          { required: true, message: '请输入问题答案', trigger: 'change' },
        ],
      },
    };
  },
  mounted() {

  },
  methods: {
    async activation() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await Api.Activation(this.formModel);
          if (res && res.code == 0) {
            message.success({ content: res.message});
            this.$router.push({ path: "/login" });
          }
        }
      });
    },
    async verifyAccount() {
      if (!this.formModel.account) {
        message.error({ content: '请输入账号'});
        return;
      }
      const res = await Api.VerifyAccount({account: this.formModel.account});
      if (res && res.code == 0) {
        if (res.isAvailable == true) {
          message.success({ content: '账号可用'});
        } else if (res.isAvailable == false) {
          message.error({ content: '账号不可用'});
        }
      }
    },
    goLogin() {
      this.$router.push({path: '/login'})
    },
    goActivation() {
      this.$router.push({path: '/activation'})
    }
  }
};
</script>
<style>
.is-input input {
  height: 44px !important;
}
</style>
<style scoped>
.is-input input {
  height: 42px !important;
}
.is-btn {
  background: #7AB7A5;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  padding: 8px 20px;
  cursor: pointer;
}
.flex {
  display: flex;
  align-items: center;
}
.ant-form-item-children {
  display: flex !important;
}
.form-de {
  font-size: 12px;
  font-weight: 400;
  color: #B6BDC1;
  line-height: 20px;
  width: 600px;
  padding-left: 20px;
}

.main-body {
  background-image: url("/images/forget-password-bg.png");
  align-items: flex-start;
  justify-content: center;
}

.form-box {
  width: 900px;

}

.form-box-sh {
  background: #FFFFFF;
  box-shadow: 0px 23px 25px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  position: relative;
  z-index: 9;
  margin-top: -10px;
  border-top-right-radius: 0
}

.form-box .header {
  display: flex;
  margin: 40px 0 0 0;
  position: relative;
  width: 100%;
  background-image: none;
}
.form-box .header .img, .form-box .header .name, .form-box .header .describe {
  flex: 1;
}
.form-box .header .img img {
  position: absolute;
  z-index: 1;
  width: 180px;
  top: -5px;
  left: 40px;
}
.form-box .header .name {
  text-align: center;
}
.form-box .header .describe {
  text-align: right;
}

.form-box .header .name {
  font-size: 28px;
  font-weight: 600;
  color: #832B75;
  line-height: 40px;
  text-shadow: 0px 0px 4px #FFFFFF;
  flex: 1;
}

.form-box .header .describe {
  font-size: 14px;
  font-weight: 400;
  color: #B6BDC1;
  line-height: 40px;
  text-shadow: 0px 0px 4px #FFFFFF;
  flex: 1;
}

.form-box .header .describe span {
  color: #832B75;
}

.btn {
  background: #832C75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
}
.btn.sh {
  border: 1px solid #832B75;
  border-radius: 5px;
  font-weight: 400;
  color: #832B75;
  line-height: 45px;
  background: #FFFFFF;
}

</style>
